import { Link } from "react-router-dom";
import { communityMeta, getDomainMeta } from "src/themes";
import global_chat from "../../../assets/img/global_chat.png";
import Footer from "../components/Footer/Footer";
import { useSelector } from "react-redux";

function Imprint() {
  const userData = useSelector((state) => state.user);
  const appData = useSelector((state) => state.app);
  const domainMeta = getDomainMeta();

  return (
    <>
      <div class="px-4 pt-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between ">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="indivne-flex items-center"
          >
            <img
              src={`https://mazutech.online/${appData.communityMeta?.logo}`}
              alt=""
              className=" object-cover w-32"
            />
          </a>
          <ul class="flex items-center lg:flex">
            <div>
              {!userData.token && (
                <Link to="/login">
                  <button
                    class="indivne-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-red-900 text-white transition duration-200 rounded shadow-md bg-deep-red-accent-400 hover:bg-deep-red-accent-700 focus:shadow-outdivne focus:outdivne-none"
                    aria-label="Sign up"
                  >
                    Sign In
                  </button>
                </Link>
              )}
            </div>
          </ul>
        </div>

        <div
          className="
           flex items-center justify-center flex-col  py-12"
        >
          <p class="pb-3 text-2xl sm:text-4xl">
            <strong>Imprint</strong>
          </p>

          <div className="flex items-center justify-center flex-col gap-4">
            <div className="flex items-center flex-col justify-center">
              <span>{`${domainMeta.header_heading2}`}</span>
              <span>{`${domainMeta.addressline1}`}</span>
              <span>{`${domainMeta.addressline2}`}</span>
            </div>
            <div className="flex items-center flex-col justify-center">
              <button>Email: {`${domainMeta.mail}`}</button>
              <button>
                Internet:{" "}
                <span className="text-[#2563EB]">{`${domainMeta.internet}`}</span>{" "}
              </button>
            </div>

            <div>
              <span className="text-2xl flex items-center justify-center">
                General Terms and Conditions
              </span>
              <button>
                <span> You can find our General Terms and Conditions {""}</span>

                <Link className="text-[#2563EB]" to="/terms">
                  {""}
                  here
                </Link>
              </button>
            </div>
          </div>

          <div className="flex items-center justify-center flex-col mt-16">
            <div className="flex justify-center items-center flex-col">
              <p class="pb-3 text-2xl sm:text-4xl">
                <strong>Liability</strong>
              </p>
              <p className=" text-justify sm:text-center">
                In the court decision, 312 O 85/98 - 'Liability for links' from
                12 May 1998 the county court in Hamburg resolved that, should
                links be included on a website, then the requisite site is
                thereby responsible for the content displayed on the linked
                site. To prevent this liability, it is necessary to expressly
                disassociate oneself from this content of these links. For this
                reason we, hereby, wish to ascertain, that we most decidedly do
                not endorse the content on these linked sites. This disclaimer
                applies to all links which appear on our website and to the
                complete content of these websites; including those underlying
                the displayed banners. These services contain links to other
                internet sites, to resources and to other users of those
                services. With this disclaimer we would like to state that we
                are also not in any way responsible for the availability of the
                external resources, or for their content; for the content of
                which we do not necessarily approve. In addition, we claim no
                responsibility for the advertising content, the products or for
                other matters which appear on these websites. Under no
                circumstances are we responsible for any loss, or damage, caused
                by the use of the content, the goods, or the services of these
                sites; we can be made neither directly, nor indirectly, liable
                for these. All issues relating to external links should be
                referred to the relevant site administrator or webmaster. We
                also wish to disassociate ourselves from offensive, illegal,
                morally unethical or objectionable content. We would be
                extremely grateful to you, if you would inform us, should you be
                confronted with such content. All rights, including those
                concerning the duplication of materials, copyright, publication
                and translation, are reserved. None of the materials publicised
                may be duplicated in any form (as photocopies, microfilms or
                used within other processes) without the written permission of
                the author; this includes duplication or distribution by
                electronic means.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Imprint;
